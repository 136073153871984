import React from "react";
import { Container, Box} from "@mui/material";
import ResponsiveAppBar from "../components/Navbar";
import QuizArea from "../components/QuizArea";
import { getOneQuestion } from "../firebase/firestore";
import { updateQuestionNumber,getInitialQuestionNumber } from "../firebase/realtimedatabase";
import Comments from "../components/Comments";
import { getOldComments } from "../firebase/realtimedatabase";

function QuizPage(props) {
  const [questionobj, setquestionobj] = React.useState(null);
  const [oldComment, setoldComment] = React.useState({});
  const subject = props.subject.toLowerCase() + "_qno";
  const getNextQuestion = async (jumpNo,next = true) => {
    let qno = await resolveQuestionNumber(subject,next,jumpNo);
    const ques = await getOneQuestion(
      props.subject.toLowerCase(),
      "Q" + qno.toString()
    );
    ques.no = qno;
    //console.log(ques);
    //call the firestore function and pass the data to the state
    setquestionobj(ques);

    localStorage.setItem(subject, qno.toString());
    if (qno % 5 == 0) {
      updateQuestionNumber(subject, qno);
    }
    getOldComments(setoldComment,subject+qno.toString());
  };
  const getPrevQuestion = async () => {
    //console.log(props.subject.toLowerCase());
    let qno = parseInt(localStorage.getItem(subject));
    if (qno > 1) {
      qno--;
    }
    const ques = await getOneQuestion(
      props.subject.toLowerCase(),
      "Q" + qno.toString()
    );
    ques.no = qno;
    //console.log(ques);
    //call the firestore function and pass the data to the state
    setquestionobj(ques);

    localStorage.setItem(subject, qno.toString());
    if (qno % 5 == 0) {
      updateQuestionNumber(subject, qno);
    }
    getOldComments(setoldComment,subject+qno.toString());
  };

  React.useEffect(() => {
    if (!questionobj) {
      getNextQuestion(null,false);
    }
  });
  return (
    <Box>
      <ResponsiveAppBar user = {props.user} />
      <Box>
        <Container
          maxWidth="md"
          sx={{
            mt: { xs: 3, sm: 5 },
            p: 0,
          }}
        >
          {questionobj && <QuizArea
            getNextQuestion={getNextQuestion}
            getPrevQuestion={getPrevQuestion}
            questionobj={questionobj}
          />}
          <Comments oldComment = {oldComment} subject = {subject} setOldComment = {setoldComment} user = {props.user}/>
        </Container>
      </Box>
    </Box>
  );
}

export default QuizPage;

//direction={side}

const resolveQuestionNumber = async(subject,next,jumpNo) => {
  let qno;
  if(jumpNo && Number(jumpNo)){
    qno = Number(jumpNo);
  }else{
    qno = parseInt(localStorage.getItem(subject));
  }
    if (!qno) {
      qno = await getInitialQuestionNumber(subject);
    }
    if (!next) {
      if (qno == 0) {
        qno++;
      }
    }
  return qno;

};