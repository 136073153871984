import React from "react";
import { Routes, Route } from "react-router-dom";
import { auth } from "./firebase/initialize";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import EnglishPracticePage from "./pages/EnglishPracticePage";
import SciencePracticePage from "./pages/SciencePracticePage";
import MathPraticePage from "./pages/MathPraticePage";
import Profile from "./pages/Profile";
import MockTestList from "./pages/MockTestList";
import MockTestQuizPage from "./pages/MockTestQuizPage";

function App() {
  console.log(localStorage.getItem("userID"));
  const [isloggedIn, setLoggedin] = React.useState(
    localStorage.getItem("userID")
  );
  const [authuser, setUser] = React.useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        if (isloggedIn == null) {
          setLoggedin(true);
        }
      } else {
        setLoggedin(null);
      }
    });
  });

  return (
    <Routes>
      <Route path="/login" element={!isloggedIn ? <LoginPage /> : <HomePage/>} />
      <Route path="/" element= <HomePage /> />
      <Route
        path="/english"
        element=<EnglishPracticePage user={authuser}/>
      />
      <Route
        path="/science"
        element=<SciencePracticePage user={authuser}/>
      />
      <Route
        path="/math"
        element=<MathPraticePage user={authuser}/>
      />
      <Route
        path="/profile"
        element={isloggedIn ? <Profile user={authuser} /> : <LoginPage />}
      />
      <Route
            path="/mock-test"
            element={<MockTestList user={authuser}/>}
        />
      <Route
            path="/mock-test-quiz"
            element={<MockTestQuizPage user={authuser}/>}
        />
    </Routes>
  );
}

export default App;
