import {
  Stack,
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React from "react";
import { signInUserWithEmail } from "../firebase/authentication";

function Login() {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleLogin = async() => {
     if(values.email === "" || values.password ===""){
      alert("Please enter your email and password");
      return;
     }
   await signInUserWithEmail(values.email, values.password);

  }
  return (
    <Stack alignItems="center" justifyContent="center">
      <TextField
        error={false}
        required
        id="email"
        label="Email"
        helperText="Incorrect Email"
        placeholder="Email"
        onChange={handleChange("email")}
        sx={{ m: 1, width: { xs: "90%", sm: "50%" } }}
      />
      <FormControl
        sx={{ m: 1,width: { xs: "90%", sm: "50%" } }}
        variant="outlined"
        error={false}
        required
        helperText="password length must be 8 characters"
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          id="password"
          helperText="password length must be 8 characters"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        <FormHelperText id="password-helper-text">
          password length must be 8 characters.
        </FormHelperText>
      </FormControl>
      <Button variant="contained" sx={{ my: 3}} onClick = {handleLogin}>
        Login
      </Button>
    </Stack>
  );
}

export default Login;
