// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_V1BM7WBVwkeE2agRw4rORud62qXTBV8",
  authDomain: "quiz-app-a208c.firebaseapp.com",
  databaseURL: "https://quiz-app-a208c.firebaseio.com",
  projectId: "quiz-app-a208c",
  storageBucket: "quiz-app-a208c.appspot.com",
  messagingSenderId: "639007876463",
  appId: "1:639007876463:web:83085b1d46c1aa3ecf749a",
  measurementId: "G-KLZZV9Z3ZD"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdgYPAjAAAAAKFjjcI8x5kK8oHwpLEgSLRBw1IP'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const database = getDatabase(app);

export {analytics, db,auth,database};