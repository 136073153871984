import * as React from "react";
import Typography from "@mui/material/Typography";
import { teal, yellow } from "@mui/material/colors";
import { Button, Paper, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MockTestCard() {
    const navigate = useNavigate();
    const openQuizPage = () => {
        navigate("/mock-test-quiz",{
            state:{
                name:"rajan"
            }
        });

    }
  return (
    <Paper sx={{ maxWidth: 360, bgcolor: yellow[600] }}>
      <Paper sx={{ maxWidth: 360, bgcolor: teal[900], my: 2, pl: 8 }} square>
        <Typography variant="h5" color="white">
          MOCK TEST 1
        </Typography>
      </Paper>
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ ml: 5 }}>
          <Box>
            <Typography variant="h6">Questions : 100</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Questions : 100</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Questions : 100</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Questions : 100</Typography>
          </Box>
        </Stack>
        <Button variant="contained" sx={{ Width: "100%" }} onClick={openQuizPage}>
          Start Test
        </Button>
      </Stack>
    </Paper>
  );
}
