import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./initialize";
import {createuserprofile, syncQuestionNumber} from "./realtimedatabase";

const getuid = () => {
  return new Promise((resolve, reject) => {
    const uid = auth.currentUser.uid;
    if (uid) {
      resolve(uid);
    } else {
      reject(undefined);
    }
  });
};

const createuserwithemail = (email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        createuserprofile(user);
        localStorage.setItem("userID", user.uid);
        localStorage.setItem("userName",user.displayName);
        // ...
        resolve();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
        reject();
      });
  });
};

const updateuserprofile = (name) => {
  return new Promise((resolve, reject) => {
    updateProfile(auth.currentUser, {
      displayName: name,
    })
      .then(() => {
        // Profile updated!
        console.log("Profile updated!");
        resolve();
        // ...
      })
      .catch((error) => {
        // An error occurred
        console.log("An error occurred: " + error.message);
        // ...
        reject();
      });
  });
};

const signInUserWithEmail = async (email, password) => {
  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    localStorage.setItem("userID", userCredentials.user.uid);
    localStorage.setItem("userName", userCredentials.user.displayName);
    await  syncQuestionNumber(userCredentials.user.uid);
  } catch (error) {
    console.log("An error occurred: " + error.message);
  }
};

const createuserwithgoogleSSO = () => {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    auth.languageCode = "it";
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        createuserprofile(user).then();
        localStorage.setItem("userID", user.uid);
        localStorage.setItem("userName",user.displayName);
        syncQuestionNumber(user.uid).then();
        //console.log(user);
        // ...
        resolve();
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code;
        //const errorMessage = error.message;
        // The email of the user's account used.
        console.log(error);
        //const email = error.customData.email;
        // The AuthCredential type that was used.
        ///const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        reject();
      });
  });
};

const createuserwithfacebookSSO = () => {
  const provider = new FacebookAuthProvider();
  provider.addScope(" email");
  provider.addScope("public_profile");
  auth.languageCode = "it";

  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      //console.log(user);
      createuserprofile(user);
      localStorage.setItem("userID", user.uid);
      localStorage.setItem("userName",user.displayName);
      syncQuestionNumber(user.uid).then();

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //const credential = FacebookAuthProvider.credentialFromResult(result);
      //const accessToken = credential.accessToken;
      //console.log(accessToken);

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      //const errorCode = error.code;
      //const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      console.log(email);
      // The AuthCredential type that was used.
      //const credential = FacebookAuthProvider.credentialFromError(error);

      // ...
    });
};

const logoutuser = () => {
  signOut(auth)
    .then(() => {
      localStorage.removeItem("userID");
      localStorage.removeItem("userName");
      console.log("Logged out.");
    })
    .catch((error) => {
      console.log(error);
    });
};

export {
  createuserwithemail,
  updateuserprofile,
  createuserwithgoogleSSO,
  createuserwithfacebookSSO,
  getuid,
  logoutuser,
  signInUserWithEmail,
};
