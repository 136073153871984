import { IconButton, Stack,Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Paper from "@mui/material/Paper";
import { createuserwithfacebookSSO, createuserwithgoogleSSO } from "../firebase/authentication";

function Ssologin() {
  const handlessologin = (name) => async (event) => {
    console.log(event.target.name);
    if (name === "google") {
      await createuserwithgoogleSSO();
      console.log("signedup successfully");
    }
    else if(name === "facebook") {
      await createuserwithfacebookSSO();
      console.log("signedup successfully");
    }
  };
  return (
    <Stack
      direction="row"
      spacing={{xs:2,sm:10}}
      sx={{ alignItems: "center", justifyContent: "center", mb: 10 }}
    >
      <Paper elevation={6} sx = {{width:{xs:"40%",sm:"30%"}}}>
        <IconButton
          color="primary"
          sx={{ borderRadius: 5, width: "100%" }}
          onClick={handlessologin("facebook")}
        >
          <FacebookIcon color="primary" fontSize="large"/>
          <Typography sx = {{fontSize:{xs:15,sm:25}}}>Facebook</Typography>
        </IconButton>
      </Paper>
      <Paper elevation={6} sx = {{width:{xs:"40%",sm:"30%"}}}>
        <IconButton
          color="error"
          sx={{ borderRadius: 5, width: "100%" }}
          onClick={handlessologin("google")}
        >
          <GoogleIcon color="orange" fontSize="large"/>
          <Typography sx = {{fontSize:{xs:15,sm:25}}}>Google</Typography>
        </IconButton>
      </Paper>
    </Stack>
  );
}

export default Ssologin;
