import React from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Button,
  Link,
} from "@mui/material";
import {logoutuser} from "../firebase/authentication"

function AppbarAvatarMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key={1} onClick={handleCloseUserMenu}>
          <Link href="/profile" underline="none">
            <Typography >Profile</Typography>
          </Link>
        </MenuItem>
        <MenuItem key={2} onClick={handleCloseUserMenu}>
          <Button  onClick = {logoutuser}>LOGOUT</Button>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default AppbarAvatarMenu;
