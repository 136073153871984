import {
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../components/Navbar";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import BiotechIcon from "@mui/icons-material/Biotech";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Link } from "react-router-dom";

function HomePage() {
  const onMockTestClick = () => {
    alert("Coming Soon");
  }
  return (
    <Box>
      <ResponsiveAppBar />
      <Box>
        <Container
          maxWidth="md"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid container width="100%" spacing={{ xs: 2, sm: 4 }} sx = {{mb:5,p:2}}>
            <Grid item xs={6}>
              <Paper elevation={6} sx={{ height: { xs: 200, sm: 300 } }}>
                <Paper
                  elevation={3}
                  sx={{ backgroundColor: "#5BB318", padding: 2 }}
                  square={true}
                >
                  <Typography variant= "h6">
                    ENGLISH
                  </Typography>
                </Paper>
                <Stack
                  direction="column"
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 3 }}
                >
                  <FontDownloadIcon
                    fontSize="large"
                    sx={{
                      width: { xs: 50, sm: 100 },
                      height: { xs: 50, sm: 100 },
                      mb:{xs:0,sm:6}
                    }}
                  />
                  <Link
                    to="/english"
                    style={{
                      "text-decoration": "none",
                      width: "100%",
                      "align-self": "center",
                    }}
                  >
                    <Button variant="outlined" fullWidth>
                      Pratice
                    </Button>
                  </Link>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={6} sx={{ height: { xs: 200, sm: 300 } }}>
                <Paper
                  elevation={3}
                  sx={{ backgroundColor: "#5BB318", padding: 2 }}
                  square={true}
                >
                  <Typography variant="h6">
                    SCIENCE
                  </Typography>
                </Paper>
                <Stack
                  direction="column"
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 3 }}
                >
                  <BiotechIcon
                    fontSize="large"
                    sx={{
                      width: { xs: 50, sm: 100 },
                      height: { xs: 50, sm: 100 },
                      mb:{xs:0,sm:6}
                    }}
                  />
                  <Link
                    to="/science"
                    style={{ "text-decoration": "none", width: "100%" }}
                  >
                    <Button variant="outlined" fullWidth>
                      Pratice
                    </Button>
                  </Link>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={6} sx={{ height: { xs: 200, sm: 300 } }}>
                <Paper
                  elevation={3}
                  sx={{ backgroundColor: "#5BB318", padding: 2 }}
                  square={true}
                >
                  <Typography variant= "h6">MATH</Typography>
                </Paper>
                <Stack
                  direction="column"
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 3 }}
                >
                  <CalculateIcon
                    fontSize="large"
                    sx={{
                      width: { xs: 50, sm: 100 },
                      height: { xs: 50, sm: 100 },
                      mb:{xs:0,sm:6}
                    }}
                  />
                  <Link
                    to="/math"
                    style={{ "text-decoration": "none", width: "100%" }}
                  >
                    <Button variant="outlined" fullWidth>
                      Pratice
                    </Button>
                  </Link>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
          <Stack
              direction="row"
              spacing={0}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: "100%", my: 5 }}
          >
            <Typography variant="body1" sx={{p:2,textAlign: "center"}}>
              Take a mock test
            </Typography>
            <Button variant="contained" sx={{ ml: -1 }} onClick={onMockTestClick}>
              Mock Test
            </Button>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default HomePage;
