import {Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Paper from "@mui/material/Paper";

const RegistrationButton = () => {
    return (<Link href="/login" underline="none">
        <Paper variant={"elevation"} elevation={1}>
            <Typography textAlign="center" sx={{m:2}}>LogIn/SignUp</Typography>
        </Paper>
    </Link>);
}

export default RegistrationButton;