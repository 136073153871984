import { doc, getDoc } from "firebase/firestore";
import {db} from "./initialize";


const getOneQuestion = async (subject,no) =>{
    const docRef = doc(db,subject,no);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        return docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
      }

}

const getMockTestQuestion = async (id) => {
    const docRef = doc(db,'mockTest',id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data of a mocktest:", docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
    }
}

export {getOneQuestion,getMockTestQuestion}

