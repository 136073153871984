import {
  Stack,
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React from "react";
import {
  createuserwithemail,
  updateuserprofile,
} from "../firebase/authentication";
import isEmail from "validator/lib/isEmail";
import { validatePassword } from "../services/validators";

function Signup() {
  const [values, setValues] = React.useState({
    email: "",
    name: "",
    password: "",
    showPassword: false,
  });

  const [error, seterror] = React.useState({
    email: false,
    password: false,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      if (!isEmail(event.target.value)) {
        seterror({ ...error, [prop]: true });
      } else {
        seterror({ ...error, [prop]: false });
      }
    } else if (prop === "password") {
      if (validatePassword(event.target.value)) {
        seterror({ ...error, [prop]: false });
      } else {
        seterror({ ...error, [prop]: true });
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const onRegisterbtnClick = async (e) => {
    console.log(e.target.value);
    console.log(values);
    await createuserwithemail(values.email, values.password);
    console.log("signup successfully");
    await updateuserprofile(values.name);
    console.log("upadet profile successfully");
  };
  return (
    <Stack alignItems="center" justifyContent="center">
      <TextField
        error={error.email}
        required
        id="email"
        label="Email"
        helperText={error.email ? "Enter a valid email" : ""}
        placeholder="Email"
        onChange={handleChange("email")}
        sx={{ m: 1, width: { xs: "90%", sm: "50%" } }}
      />
      <TextField
        required
        id="Name"
        label="Full Name"
        placeholder="Full Name"
        onChange={handleChange("name")}
        sx={{ m: 1, width: { xs: "90%", sm: "50%" } }}
      />
      <FormControl
        sx={{ m: 1, width: { xs: "90%", sm: "50%" } }}
        variant="outlined"
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          error={error.password}
          required
          id="password"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        <FormHelperText id="password-helper-text">
          password length must be 8 characters.
        </FormHelperText>
      </FormControl>
      <Button variant="contained" sx={{ my: 3 }} onClick={onRegisterbtnClick}>
        Register
      </Button>
    </Stack>
  );
}

export default Signup;
