import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import {getOldComments, postComment} from "../firebase/realtimedatabase";
import ViewComment from "./ViewComment";

function comments(props) {
  const [comment, setcomment] = useState("");
  const handleChange = (event) => {
    setcomment(event.target.value);
  };
  const commentSubmit = async () => {
      if(props.user?.uid){
    if (comment !== "") {
      let qno = localStorage.getItem(props.subject);
      let userName = localStorage.getItem("userName");
      await postComment({userName:userName,comment:comment  }, props.subject + qno);
      setcomment("");
      await getOldComments(props.setOldComment,props.subject + qno);
    }}else{
          alert("Please login First");
      }

  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        my: 5,
        mx: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <TextField
        id="filled-multiline-static"
        sx={{ width: { xs: "100%", md: "60%" } }}
        multiline
        placeholder="Add Your Comment"
        variant="filled"
        value={comment}
        onChange={handleChange}
      />
      <Button variant="contained" onClick={commentSubmit}>
        Add Comment
      </Button>
      {Object.keys(props.oldComment).reverse().map((key) => (
        <ViewComment userName={props.oldComment[key].userName} comment={props.oldComment[key].comment} key={key} />
      ))}
    </Stack>
  );
}

export default comments;
