const menuitems = [
  {
    href: "/",
    key: "HOME",
  },
  {
    href: "/english",
    key: "ENGLISH",
  },
  {
    href: "/science",
    key: "SCIENCE",
  },
  {
    href: "/math",
    key: "MATH",
  },
];


export {menuitems}


// {
//   href: "/login",
//       key: "CONTACT US",
// },