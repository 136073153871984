import React from 'react'
import { useLocation } from 'react-router-dom';
import MockTestQuizArea from "../components/MockTestQuizArea";
import ResponsiveAppBar from "../components/Navbar";
import {Box, Container} from "@mui/material";
import ProgressTimer from "../components/ProgressTimer";
import {getMockTestQuestion} from "../firebase/firestore";

function MockTestQuizPage(props) {
    const state = useLocation();
    React.useEffect(async () => {
        const ques = await getMockTestQuestion("B0EudXQgrhgzHuMk4hwl");
        console.log(ques);
    });
    console.log(state);
    return(<Box>
        <ResponsiveAppBar user = {props.user} />
        <Box>
            <Container
                maxWidth="md"
                sx={{
                    mt: { xs: 3, sm: 5 },
                    p: 0,
                }}
            >
                <ProgressTimer/>
                <MockTestQuizArea/>
            </Container>
        </Box>
    </Box>);
}

export default MockTestQuizPage;