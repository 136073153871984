import React from "react";
import { Box, Container } from "@mui/material";
import ResponsiveAppBar from "../components/Navbar";
import MockTestCard from "../components/MockTestCard"
import Grid from '@mui/material/Grid';
//import LinearDeterminate from "../components/ProgressTimer";

function MockTestList() {
  return (
    <Box>
      <ResponsiveAppBar />
      <Box sx={{}}>
        <Container
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 2 }} justifyContent="center">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <MockTestCard/>
                </Grid>
            </Grid>
            

        </Container>
      </Box>
    </Box>
  );
}

export default MockTestList;
