import React from "react";
import {
  Box,
  Container,
  Paper,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import ResponsiveAppBar from "../components/Navbar";

function Profile(props) {
  //console.log(props);
  return (
    <Box>
      <ResponsiveAppBar />
      <Box>
        <Container
          maxWidth="md"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Paper
            elevation={4}
            sx={{ textAlign: "center", mt: 5, bgcolor: "#ececec",pb:10 }}
          >
            <Stack
              spacing={5}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  bgcolor: "#f62518",
                  width: { xs: 50, sm: 100 },
                  height: { xs: 50, sm: 100 },
                  mt:-3
                }}
              >
                {props.user?props.user.displayName[0]:"R"}
              </Avatar>
              <Stack
                direction="column"
                sx={{ textAlign: "justify",width:"100%",}}
                spacing={0}
              >
                <Typography variant="body1" sx={{mx:{xs:0,sm:10}}}>Name</Typography>
                <Paper elevation={2} sx={{px:5,py:1,mx:{xs:0,sm:10}}}><Typography variant="h6" sx={{ bgcolor: "#ffffff" }}>
                  {props.user?props.user.displayName:"User Name"}
                </Typography></Paper>
                
              </Stack>
              <Stack
                direction="column"
                sx={{ textAlign: "justify",width:"100%"}}
                spacing={0}
              >
                <Typography variant="body1" sx={{mx:{xs:0,sm:10}}}>Email</Typography>
                <Paper elevation={2} sx={{px:5,py:1,mx:{xs:0,sm:10}}}><Typography variant="h6" sx={{ bgcolor: "#ffffff" }}>
                 {props.user?props.user.email:"User Email"}
                </Typography></Paper>
                
              </Stack>
            </Stack>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}

export default Profile;
