import React from "react";
import {
    Stack,
    Typography,
    CardMedia,
    Button,
    Paper,
    Slide
} from "@mui/material";
import { red, green } from "@mui/material/colors";
//import { useSearchParams } from "react-router-dom";

const redcolor = red[500];
const greencolor = green[500];
const initialbtnColor = {
    a: "#fff",
    b: "#fff",
    c: "#fff",
    d: "#fff",
};

function MockTestQuizArea(props) {
    const [checked, setChecked] = React.useState(true);
    const [btncolor, setbtncolor] = React.useState(initialbtnColor);
    const [btndisabled, setbtndisabled] = React.useState(false);
    //const [jumpValue, setJumpValue] = React.useState(props.questionobj.no+1);
    //const [searchParams, setSearchParams] = useSearchParams();
    //const [side,setside] = React.useState("left"); //implement this animation later

    const handleGetNextQuestion = (btn) => async () => {
        //setSearchParams({qno:1})
        await getNextandPreviousQuestion(setbtndisabled,setbtncolor,btn,props.getNextQuestion, props.getPrevQuestion,setChecked);
    };

    // React.useEffect(() => {
    //   props.getQuestion();
    // });

    const onOptionBtnClick = (option) => () => {
        setbtndisabled(true);
        //console.log(searchParams.get('qno'));
        if (props.questionobj[option] === props.questionobj.answer) {
            setbtncolor({ ...btncolor, [option]: greencolor });
        } else {
            var key = Object.keys(props.questionobj).filter(function (key) {
                if (key === "answer") return false;
                return props.questionobj[key] === props.questionobj.answer;
            })[0];
            setbtncolor({ ...btncolor, [key]: greencolor, [option]: redcolor });
        }
    };
    return (
        <Slide in={checked} mountOnEnter unmountOnExit>
            <Paper
                elevation={5}
                sx={{
                    width: { xs: "80%", sm: "90%" },
                    alignSelf: "center",
                    textAlign: "center",
                    p: 5,
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: `Q${props.questionobj ? props.questionobj.no : "1"}.   ${
                                props.questionobj
                                    ? props.questionobj.question
                                    : "Loading------------"
                            }`,
                        }}
                    />
                    {props.questionobj && (
                        <CardMedia
                            image={props.questionobj.img ? props.questionobj.img : "#"}
                            sx={{ width: 100, height: 100 }}
                        />
                    )}
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 2, sm: 6 }}
                    >
                        <Paper
                            elevation={4}
                            sx={{
                                minWidth: { xs: 280, sm: 300 },
                                maxWidth: { xs: 280, sm: 300 },
                                borderRadius: 10,
                                bgcolor: btncolor.a,
                                color: "#000",
                            }}
                        >
                            <Button
                                sx={{
                                    width: "100%",
                                    textTransform: "none",
                                    color: "text.primary",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                                onClick={onOptionBtnClick("a")}
                                disabled={btndisabled}
                            >
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textTransform: "none",
                                        color: "text.primary",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: `${
                                            props.questionobj ? props.questionobj.a : "Loading---"
                                        }`,
                                    }}
                                />
                            </Button>
                        </Paper>
                        <Paper
                            elevation={4}
                            sx={{
                                minWidth: { xs: 280, sm: 300 },
                                maxWidth: { xs: 280, sm: 300 },
                                borderRadius: 10,
                                bgcolor: btncolor.b,
                            }}
                        >
                            <Button
                                sx={{
                                    minWidth: { xs: 280, sm: 300 },
                                    maxWidth: { xs: 280, sm: 300 },
                                    textTransform: "none",
                                    color: "text.primary",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                                onClick={onOptionBtnClick("b")}
                                disabled={btndisabled}
                            >
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textTransform: "none",
                                        color: "text.primary",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: `${
                                            props.questionobj ? props.questionobj.b : "Loading---"
                                        }`,
                                    }}
                                />
                            </Button>
                        </Paper>
                    </Stack>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 2, sm: 6 }}
                    >
                        <Paper
                            elevation={4}
                            sx={{
                                minWidth: { xs: 280, sm: 300 },
                                borderRadius: 10,
                                bgcolor: btncolor.c,
                            }}
                        >
                            <Button
                                sx={{
                                    width: "100%",
                                    textTransform: "none",
                                    color: "text.primary",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                                onClick={onOptionBtnClick("c")}
                                disabled={btndisabled}
                            >
                                {" "}
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textTransform: "none",
                                        color: "text.primary",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: `${
                                            props.questionobj ? props.questionobj.c : "Loading---"
                                        }`,
                                    }}
                                />
                            </Button>
                        </Paper>
                        <Paper
                            elevation={4}
                            sx={{
                                minWidth: { xs: 280, sm: 300 },
                                maxWidth: { xs: 280, sm: 300 },
                                borderRadius: 10,
                                bgcolor: btncolor.d,
                            }}
                        >
                            <Button
                                sx={{
                                    width: "100%",
                                    textTransform: "none",
                                    color: "text.primary",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                                onClick={onOptionBtnClick("d")}
                                disabled={btndisabled}
                            >
                                {" "}
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textTransform: "none",
                                        color: "text.primary",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: `${
                                            props.questionobj ? props.questionobj.d : "Loading---"
                                        }`,
                                    }}
                                />
                            </Button>
                        </Paper>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 6 }}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: { xs: "100%", sm: "70%" }, p: 3 }}
                    >
                        <Paper elevation={4}>
                            <Button
                                sx={{ width: "100%" }}
                                variant="outlined"
                                onClick={handleGetNextQuestion("prev")}
                            >
                                previous
                            </Button>
                        </Paper>

                        <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1 }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Paper elevation={4}>
                                <Button
                                    sx={{ width: "100%" }}
                                    variant="contained"
                                    onClick={handleGetNextQuestion("next")}
                                >
                                    Next
                                </Button>
                            </Paper>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Slide>
    );
}

export default MockTestQuizArea;

//../static/img/triangle.png
//dangerouslySetInnerHTML


const getNextandPreviousQuestion = async (setbtndisabled,setbtncolor,btn,getNextQuestion,getPrevQuestion,jumpValue,setJumpValue,setChecked) => {
    setbtndisabled(false);
    setbtncolor(initialbtnColor);
    if (btn === "next") {
        await getNextQuestion(jumpValue);
        setJumpValue(Number(jumpValue)+1);
    } else {
        await getPrevQuestion(jumpValue);
        setJumpValue(Number(jumpValue)-1);
    }
    setChecked((prev) => !prev);
    setTimeout(() => {
        setChecked((prev) => !prev);
    }, 300);
}

// <TextField
//     id="outlined-basic"
//     variant="standard"
//     sx={{ width: 50, mb: 1 }}
//     value={jumpValue}
//     onChange={handleChangeJumpValue}
// />