import React from 'react'
import {Paper, Typography} from "@mui/material";

function ViewComment({userName,comment}) {
  return (
      <Paper elevation={2} sx={{width:{xs:"100%",md:"60%"}}}>
        <Typography variant="body1" sx={{ bgcolor: "#f5f5f5" }}>{userName}</Typography>
        <Typography variant="body2">{comment}</Typography>
      </Paper>
  )
}

export default ViewComment