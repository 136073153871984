import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Paper, Typography } from "@mui/material";
const totalTime = 10800;
export default function ProgressTimer() {
  const [progress, setProgress] = React.useState(0);
  const [progressPercentage, setProgressPercentage] = React.useState(
    ((totalTime - progress) / totalTime) * 100
  );
  const [hour, setHour] = React.useState(2);
  const [minute, setMinute] = React.useState(59);
  const [second, setSecond] = React.useState(59);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgressPercentage(((totalTime - progress) / totalTime) * 100);
      setProgress((oldProgress) => oldProgress + 1);
      if (minute === 0) {
        setMinute(59);
        setHour((hr) => hr - 1);
      }
      if (second === 0) {
        setSecond(59);
        setMinute((min) => min - 1);
      } else {
        setSecond((sec) => sec - 1);
      }
    }, 10);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <Paper sx={{ width: "100%", borderRadius: 10, position: "relative" }}>
      <LinearProgress
        variant="determinate"
        value={progressPercentage}
        color="secondary"
        sx={{ height: 50, borderRadius: 10 }}
      />
      <Typography
        sx={{
          position: "absolute",
          left: "40%",
          top: 15,
          fontSize: "larger",
          fontWeight: "bold"
        }}
      >{`${hour} : ${minute} : ${second}`}</Typography>
    </Paper>
  );
}