import {database} from "./initialize";
import {ref, set, get, update, push} from "firebase/database";

const createuserprofile = async (user) => {
    const dataref = ref(database, "users/" + user.uid);
    try {
        const snapshot = await get(dataref);
        if (snapshot.exists()) {
            console.log(snapshot.val());
            return;
        } else {
            console.log("No data available");
        }
    } catch (error) {
        console.error(error);
    }
    await set(dataref, {
        username: user.displayName,
        email: user.email,
        profile_picture: user.photoURL,
        science_qno: 0,
        english_qno: 0,
        math_qno: 0,
        mock_test_combined_score: {
            no_mock_test_attempted: 0,
            total_questions_attempted: 0,
            total_correct_guess: 0,
            total_wrong_guess: 0,
        },
        last_mock_test: {
            mock_test_id: "T1",
            no_question_attempted: 0,
            no_correct_guess: 0,
            no_wrong_guess: 0,
        },
    });
    console.log("added data to realtimedatabase");
};

const syncQuestionNumber = async (uid) => {
    const dataRef = [ref(database, `/users/${uid}/science_qno`), ref(database, `/users/${uid}/math_qno`), ref(database, `/users/${uid}/english_qno`)];
    try {
        for (let i = 0; i < 3; i++) {
            const snapshot = await get(dataRef[i]);
            if (snapshot.exists()) {
                //console.log(typeof snapshot.val());
                if (i == 1) {
                    localStorage.setItem("math_qno", snapshot.val().toString());
                } else if (i == 2) {
                    localStorage.setItem("english_qno", snapshot.val().toString());
                } else {
                    localStorage.setItem("science_qno", snapshot.val().toString());
                }
            } else {
                console.log("No data available");
            }
        }
    } catch (error) {
        console.error(error);
    }

}
const updateQuestionNumber = async (subject, quesNumber) => {
    const uid = localStorage.getItem("userID");
    const updates = {};
    updates[`/users/${uid}/${subject}`] = quesNumber;
    try {
        await update(ref(database), updates);
    } catch (e) {
        console.log(e);
    }
};

const getInitialQuestionNumber = async (subject) => {
    const uid = localStorage.getItem("userID");
    const dataref = ref(database, `/users/${uid}/${subject}`);
    try {
        const snapshot = await get(dataref);
        if (snapshot.exists()) {
            //console.log(typeof snapshot.val());
            return snapshot.val();
        } else {
            console.log("No data available");
            return 0;
        }
    } catch (error) {
        console.error(error);
        return 0;
    }
};

const getOldComments = async (setOldComment, qid) => {
    const dataref = ref(database, `/comments/${qid}`);
    try {
        const snapshot = await get(dataref);
        if (snapshot.exists()) {
            //console.log(typeof snapshot.val());
            //console.log(snapshot.val());
            setOldComment(snapshot.val());
        } else {
            console.log("No data available");
            setOldComment([]);
        }
    } catch (error) {
        console.error(error);
        setOldComment([]);
    }
}

const postComment = async (comment, qid) => {
    const dataref = ref(database, `/comments/${qid}`);
    const postref = push(dataref);
    try {
        await set(postref, comment);
    } catch (e) {
        console.error(e);
    }
}

export {createuserprofile, updateQuestionNumber, getInitialQuestionNumber, getOldComments,syncQuestionNumber,postComment};
