import React from "react";
import ResponsiveAppBar from "../components/Navbar";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Divider,
  Link,
} from "@mui/material";
import Signup from "../components/Signup";
import Login from "../components/Login";
import Ssologin from "../components/Ssologin";

function LoginPage() {
  const [toggleLoginSignup, settoggleLoginSignup] = React.useState("none");
  const handletoggle = (name) => () => {
    //console.log(event.target.name);
    settoggleLoginSignup(name);
  };
  return (
    <Box>
      <ResponsiveAppBar isLoginPage = {true}/>
      <Box>
        <Container
          maxWidth="md"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Stack
            direction="column"
            sx={{
              width: "100%",
            }}
          >
            <Box sx={{ mt: 5 }}>
              <Typography
                variant="h3"
                color="initial"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Place to prepare All after 10th Entrances
              </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mt: 5, textAlign: "center" }}
              >
                More than 10 thousnand Multiple choice questions and daily test.
              </Typography>
            </Box>
            <Divider id = "register"
              orientation="horizontal"
              sx={{
                m: 1,
                width: "80%",
                alignSelf: "center",
                border: "2px solid black",
              }}
            ></Divider>
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "center", my: 3 }}
            >
              <Stack
                direction="row"
                spacing={10}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button id = "login"
                  variant="outlined"
                  onClick={handletoggle("login")}
                  sx={{ width: "30%" }}
                >
                  <Link href = "#register" underline = "none">Login</Link>
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={handletoggle("register")}
                  sx={{ width: "30%", color : "white" }}
                >
                  <Link href = "#register" underline = "none" color = "inherit">Register</Link>
                </Button>
              </Stack>
            </Stack>
            {toggleLoginSignup === "register" && <Signup />}
            {toggleLoginSignup === "login" && <Login />}
            <Ssologin />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default LoginPage;
